import React from 'react';
import { Link } from 'react-router-dom';
function PaymentReport() {
    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Payment Report</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active">Payment Report</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-12">
                                <div className="input-group">
                                    <i className="fa fa-search darrow-i"></i>
                                    <input className="input-box" type="text" placeholder="Search..." />
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12 pr-2">
                                <button className="search_btn mt-0"> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-md-2 col-sm-2 col-12 pl-2">
                                <button className="search_btn clear_btn mt-0"><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <table className="table table-bordered table-hover mb-0">
                                    <thead>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Phone No</th>
                                            <th>Amount</th>
                                            {/* <th>Gender</th>
                                            <th className="text-center w130">Status</th> */}
                                            <th className="text-center w130">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>01</td>
                                            <td>Sanjay</td>
                                            <td>Patel</td>
                                            <td>sanjay@gmail.com</td>
                                            <td>0123456789</td>
                                            <td>$30</td>
                                            {/* <td>Male</td>
                                            <td>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                    <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                        <span className="onoffswitch-inner active-deactive"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                {/* <button className="btn btn-info mr-2" data-toggle="modal" data-target="#Details"><i className="ace-icon fa fa-pencil"></i></button> */}
                                                <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>02</td>
                                            <td>Nilesh</td>
                                            <td>Ahir</td>
                                            <td>nilesh@gmail.com</td>
                                            <td>9876543210</td>
                                            <td>$35</td>
                                            {/* <td>Male</td>
                                            <td>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                    <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                        <span className="onoffswitch-inner active-deactive"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                {/* <button className="btn btn-info mr-2" data-toggle="modal" data-target="#Details"><i className="ace-icon fa fa-pencil"></i></button> */}
                                                <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>03</td>
                                            <td>Harsh</td>
                                            <td>Patel</td>
                                            <td>harsh@gmail.com</td>
                                            <td>8796543210</td>
                                            <td>$26</td>
                                            {/* <td>Male</td>
                                            <td>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                    <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                        <span className="onoffswitch-inner active-deactive"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                {/* <button className="btn btn-info mr-2" data-toggle="modal" data-target="#Details"><i className="ace-icon fa fa-pencil"></i></button> */}
                                                <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>04</td>
                                            <td>Dhaval</td>
                                            <td>Patel</td>
                                            <td>dhaval@gmail.com</td>
                                            <td>9874563210</td>
                                            <td>$28</td>
                                            {/* <td>Male</td>
                                            <td>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                    <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                        <span className="onoffswitch-inner active-deactive"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                {/* <button className="btn btn-info mr-2" data-toggle="modal" data-target="#Details"><i className="ace-icon fa fa-pencil"></i></button> */}
                                                <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>05</td>
                                            <td>Yatin</td>
                                            <td>Patel</td>
                                            <td>yatin@gmail.com</td>
                                            <td>1234567890</td>
                                            <td>$27</td>
                                            {/* <td>Male</td>
                                            <td>
                                                <div className="onoffswitch">
                                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch{{$index}}" />
                                                    <label className="onoffswitch-label" htmlFor="myonoffswitch{{$index}}">
                                                        <span className="onoffswitch-inner active-deactive"></span>
                                                        <span className="onoffswitch-switch"></span>
                                                    </label>
                                                </div>
                                            </td> */}
                                            <td className="text-center">
                                                {/* <button className="btn btn-info mr-2" data-toggle="modal" data-target="#Details"><i className="ace-icon fa fa-pencil"></i></button> */}
                                                <button className="btn btn-danger"><i className="ace-icon fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                        <tr className="d-none">
                                            <td className="text-center" colspan="10" ng-if="List.length == 0">
                                                No Record Found
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* <paging ng-if="List.length > 0" pagenumber="PageNumber" pagesize="PageSize" totalrecords="TotalRecords" getlist="GetList"></paging> */}
                                <paging pagenumber="PageNumber" pagesize="PageSize" totalrecords="TotalRecords" getlist="GetList" className="">
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <div className="pagination">
                                            <a href={() => false}>«</a>
                                            <a className="active" href={() => false}>1</a><a className="" href={() => false}>2</a><a className="" href={() => false}>3</a><a className="" href={() => false} >4</a><a className="" href={() => false}>5</a><a className="" href={() => false}>6</a>
                                            <a href={() => false}>»</a>
                                        </div>
                                    </div>
                                </paging>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div id="Details" className="modal fade">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Update Trainer</h4>
                            <span className="close" data-dismiss="modal" aria-label="Close" aria-hidden="true">&times;</span>
                        </div>
                        <div className="modal-body pl-4 pr-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Sr No.</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>First Name</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Last Name</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Email</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="email" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Phone No</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="text" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <label>Age</label>
                                    <div className="input-group">
                                        <input ng-model="Details.question" className="input-box" type="text" />
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-4 col-12">
                                    <label>Gender</label>
                                    <div className="input-group">
                                        <i className="fa fa-angle-down darrow-i"></i>
                                        <select convert-number className="input-box">
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-12">
                                    <label>Status</label>
                                    <div className="onoffswitch m-0">
                                        <input ng-model="Details.status" ng-true-value="1" ng-false-value="2" type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" />
                                        <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                            <span className="onoffswitch-inner active-deactive"></span>
                                            <span className="onoffswitch-switch"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-dismiss="modal" className="btn cancel_btn">Cancel</button>
                            <button type="button" className="btn save_btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PaymentReport;