import axios from 'axios';
import React, { useState } from 'react';
import { apiUrl, PORT } from '../environment/environment';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';

function ResetPassword() {
    const history = useHistory();
    localStorage.clear();

    var uri = window.location.pathname;
    let splituri = uri.split("/");
    let usertype = splituri[1];

    const [user, setUser] = useState({
        password: "", confirmpassword: ""
    });

    const handleInputs = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const Reset = async (e) => {
        e.preventDefault();

        if (user.email === "") {
            //window.alert("Please enter Email.");
            swal({
                title: "Error!",
                text: "Please enter Email.",
                icon: "error",
                button: true
            })
            return false;
        }
        else if (user.confirmpassword === "") {
            //window.alert("Please enter Confirm Password.");
            swal({
                title: "Error!",
                text: "Please enter Confirm Password.",
                icon: "error",
                button: true
            })
            return false;
        }

        await axios.post(`${apiUrl}${PORT}/${usertype}/account/resetpassword/${splituri[4]}`, { "password": user.password, "confirmpassword": user.confirmpassword }, {
        }).then(function (response) {
            if (response.data.status === 1) {
                //window.alert(response.data.message);
                swal({
                    title: "Success!",
                    text: response.data.message,
                    icon: "success",
                    button: true
                })
                window.location.href = "/login";
                //history.push("/login");
            }
            else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
                //window.alert(response.data.message);
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <div className="bg-img">
                <div className="container">
                    <div className="col-5 mx-auto">
                        <div className="updatepassword">
                            <div className="row">
                                <div className="col-12"><h4 className="change-pass">Reset Password</h4></div>
                                <form className="w-100">
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <i className="fa fa-key regi-i"></i>
                                        <input onChange={(e) => handleInputs(e)} name="password" type="password" placeholder="Password" className="input_box" />
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <i className="fa fa-key regi-i"></i>
                                        <input onChange={(e) => handleInputs(e)} name="confirmpassword" type="password" placeholder="Confirm Password" className="input_box" />
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-12">
                                        <button onClick={(e) => Reset(e)} type="submit" className="login_btn">Reset</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
