import axios from 'axios';
import $ from 'jquery';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { HashRouter as Router, NavLink, Route, Switch } from 'react-router-dom';
import './App.css';
import BasicMovements from './components/BasicMovements';
import Client from "./components/Client";
import ClientBookingHistory from './components/ClientBookingHistory';
import ClientRatings from './components/ClientRatings';
import EquipmentListing from './components/EquipmentListing';
import ForgotPassword from "./components/ForgotPassword";
import Login from './components/Login';
import ManageGoalTypes from './components/ManageGoalType';
import Notifications from './components/Others/Notifications';
import PaymentSetup from './components/Others/PaymentSetup';
import ProfileManagment from './components/Others/ProfileManagement';
import Settings from './components/Others/Settings';
import PaymentReport from './components/PaymentReport';
import ResetPassword from "./components/ResetPassword";
import Subscribers from './components/Subscribers';
import Trainer from "./components/Trainer";
import TrainerBookingHistory from "./components/TrainerBookingHistory";
import Transactions from './components/Transactions';
import UserManagement from "./components/UserManagement";
import ClientDetails from './components/UserManagement/ClientDetails';
import TrainerDetails from './components/UserManagement/TrainerDetails';
import WorkOutCategory from './components/WorkOutCategory';
import CountryListing from './components/CountryListing';
// import WorkOutForm from './components/WorkOutForm';
import WorkoutForm from './components/WorkOutForm';
import { apiUrl, PORT } from './environment/environment';
// import Dashboard from './components/Masters/Dashboard';
function App() {
  const [isLogin, setIsLogin] = useState(false);
  const history = useHistory();
  const loginuserdetail = localStorage.getItem('token');
  if (loginuserdetail === "" || loginuserdetail === null || loginuserdetail === undefined) {
    history.push("/");
  }

  useEffect(() => {
    $(".sidebar-dropdown").each(function (item) {
      $(this).removeClass("active");
      if ($(this).find('a').attr('href') === window.location.pathname) {
        $(this).addClass("active");
      }
    });
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = token;
      axios.get(`${apiUrl}${PORT}/account/verifytoken`, {}, {
      }).then(function (response) {
        if (response.data.status === 1) {
          setIsLogin(true);
        }
      }).catch(function (error) {
        console.log(error);
      });
    }
  });

  const LogOut = async (e) => {
    e.preventDefault();

    localStorage.clear();
    window.location.href = "/";
    //history.push("/");
  }
  const showHidemenu = (e) => {
    document.querySelector('#mainmenu').classList.toggle('fliph');
  };

  return (
    <>
      <Router>
        {/* <Switch> */}
          <Route exact path="/"><Login></Login></Route>
          <Route path="/login"><Login></Login></Route>
          <Route path="/logout"><Login></Login></Route>
          {/* <Route exact path="/" component={Login} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Login} /> */}
          <Route path="/forgotpassword"><ForgotPassword></ForgotPassword></Route>
          <Route path="/:usertype?/account/resetpassword/:credentials?"><ResetPassword></ResetPassword></Route>

          {
            (isLogin === true) ?
              <>
                <div className="main">
                  <button className="menubtn btn btn-sm btn-dark" onClick={showHidemenu}>
                    <i className="fa fa-bars"></i>
                  </button>
                  <aside>
                    <div className="sidebar left scroll-color" id="mainmenu">
                      <div className="bg_white">
                        <NavLink to="/" className="admin-logo">
                          <img src="/img/KNKTLogo.png" alt="Logo" />
                        </NavLink>
                        <button className="close-icon btn" onClick={showHidemenu}>
                          <i className="fa fa-bars"></i>
                        </button>
                        {/* <button className="button-left" onClick={showHidemenu}></button> */}
                      </div>
                      <ul className="list-sidebar bg-defoult">
                        <li><NavLink to="/dashboard" className={({ isActive }) => isActive ? 'active' : ''}><i className="fa fa-th-large"></i><span className="nav-label">Dashboard</span></NavLink></li>
                        <li>
                          <button data-toggle="collapse" data-target="#UserManagement" className="collapsed"><i className="fa fa-user"></i><span className="nav-label">User Management</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="UserManagement">
                            <li><NavLink
                              to="/usermanagement"
                              className={({ isActive }) => isActive ? 'active' : ''}
                            >
                              Staff/Sub-Admin
                            </NavLink></li>
                            <li><NavLink to="/trainer" className={({ isActive }) => isActive ? 'active' : ''}>Trainer</NavLink></li>
                            <li><NavLink to="/client" className={({ isActive }) => isActive ? 'active' : ''}>Client</NavLink></li>
                            <li><NavLink to="/trainerbookinghistory" className={({ isActive }) => isActive ? 'active' : ''}>Trainer Booking History</NavLink></li>
                            <li><NavLink to="/clientbookinghistory" className={({ isActive }) => isActive ? 'active' : ''}>Client Booking History</NavLink></li>
                          </ul>
                        </li>
                        <li>
                          <button data-toggle="collapse" data-target="#workout" className="collapsed"><i className="fa fa-file-text-o"></i><span className="nav-label">Workout Forms</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="workout">
                            <li><NavLink to="/workoutforms" className={({ isActive }) => isActive ? 'active' : ''}>Workout Forms</NavLink></li>
                            <li><NavLink to="/workoutcategory" className={({ isActive }) => isActive ? 'active' : ''}>Workout Category</NavLink></li>
                            <li><NavLink to="/basicMovements" className={({ isActive }) => isActive ? 'active' : ''}>Basic Movements</NavLink></li>
                          </ul>
                        </li>
                        <li>
                          <button data-toggle="collapse" data-target="#rating" className="collapsed"><i className="fa fa-star"></i><span className="nav-label">Ratings</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="rating">
                            <li><NavLink to="/clientratings" className={({ isActive }) => isActive ? 'active' : ''}>Client Ratings</NavLink></li>
                          </ul>
                        </li>
                        <li>
                          <button data-toggle="collapse" data-target="#master" className="collapsed"><i className="fa fa-database"></i><span className="nav-label">Master</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="master">
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/managegoalstype">Manage Goals Type</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/equipmentlisting">Equipment Listing</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/countrylisting" >Country Listing</NavLink></li>
                          </ul>
                        </li>
                        <li>
                          <button data-toggle="collapse" data-target="#subscription" className="collapsed"><i className="fa fa-money"></i><span className="nav-label">Subscriptions/Payment</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="subscription">
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/subscribers">Subscribers/Plan</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/transactions">Transactions</NavLink></li>
                            {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/paymentreport">Payment Report</NavLink></li> */}
                          </ul>
                        </li>
                        <li>
                          <button data-toggle="collapse" data-target="#others" className="collapsed"><i className="fa fa-th-list"></i><span className="nav-label">Others</span><span className="fa fa-chevron-left pull-right fa-sm"></span></button>
                          <ul className="sub-menu collapse" id="others">
                            {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/paymentsetup">Payment Set-Up</NavLink></li> */}
                            {/* <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/profilemanagement">Profile Management</NavLink></li> */}
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/notifications">Notifications</NavLink></li>
                            <li><NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/settings">Setting</NavLink></li>
                          </ul>
                        </li>
                        {/* <li><button onClick={(e) => LogOut(e)}><i className="fa fa-sign-out"></i><span className="nav-label">LogOut</span></button></li> */}
                      </ul>
                    </div>
                    <div className="main-content">
                      <header className="header">
                        <nav className="navbar navbar-toggleable-md navbar-light py-0">
                          <div className="col-md-12 col-12">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <h3 className="head-text">Dashboard </h3>
                                <ul className="navbar-nav float-right">
                                  <li className="nav-item dropdown user-menu">
                                    <a className="nav-link dropdown-toggle user-i" href={() => false} title="logout" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-expanded="true">
                                      <i className="fa fa-user-circle-o"></i>
                                    </a>
                                    <div className="dropdown-menu adlogin" aria-labelledby="navbarDropdownMenuLink">
                                      <button className="logout"><NavLink to="profilemanagement" className={({ isActive }) => isActive ? 'active' : ''}><i className="fa fa-user pr-2"></i>Profile Management</NavLink></button>
                                      <button className="logout" onClick={(e) => LogOut(e)}><i className="fa fa-sign-out pr-2"></i><span className="nav-label">LogOut</span></button>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>
                        </nav>
                      </header>
                      <div className="main-content-inner">
                        <Route path="/trainer"><Trainer></Trainer></Route>
                        <Route path="/client"><Client></Client></Route>
                        <Route path="/usermanagement"><UserManagement></UserManagement></Route>
                        {/* <Route path="/trainer" component={Trainer} />
                        <Route path="/client" component={Client} />
                        <Route path="/usermanagement" component={UserManagement} /> */}
                        <Route path="/trainerbookinghistory"><TrainerBookingHistory></TrainerBookingHistory></Route>
                        <Route path="/clientbookinghistory"><ClientBookingHistory></ClientBookingHistory></Route>
                        <Route path="/workoutforms"><WorkoutForm></WorkoutForm></Route>
                        <Route path="/clientratings"><ClientRatings></ClientRatings></Route>
                        <Route path="/managegoalstype"><ManageGoalTypes></ManageGoalTypes></Route>
                        <Route path="/equipmentlisting"><EquipmentListing></EquipmentListing></Route>
                        <Route path="/workoutcategory"><WorkOutCategory></WorkOutCategory></Route>
                        <Route path="/subscribers"><Subscribers></Subscribers></Route>
                        <Route path="/transactions"><Transactions></Transactions></Route>
                        <Route path="/paymentreport"><PaymentReport></PaymentReport></Route>
                        <Route path="/paymentsetup"><PaymentSetup></PaymentSetup></Route>
                        <Route path="/profilemanagement"><ProfileManagment></ProfileManagment></Route>
                        <Route path="/notifications"><Notifications></Notifications></Route>
                        <Route path="/settings"><Settings></Settings></Route>
                        <Route path="/clientdetails"><ClientDetails></ClientDetails></Route>
                        <Route path="/trainerdetails"><TrainerDetails></TrainerDetails></Route>
                        <Route path="/basicMovements"><BasicMovements></BasicMovements></Route>
                        <Route path="/countrylisting"><CountryListing></CountryListing></Route>
                        {/* <Route path="/dashboard"><Dashboard></Dashboard></Route> */}
                      </div>
                      <footer>
                        <div className="footer-content">
                          <p className="mb-0">&copy; 2022 - KNKT Fitness Management</p>
                        </div>
                      </footer>
                    </div>
                  </aside>
                </div>

              </> : null
          }
        {/* </Switch> */}
      </Router>
    </>
  );
}

export default App;
