import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
import { apiUrl, PORT } from '../environment/environment';
import Pagination from './Pagination/Pagination';

function BasicMovements() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));

    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [searchname, setSearchName] = useState('');
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [list, setList] = useState([]);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Movement Name', value: 'name', dir: 1 },
        { name: 'Value', value: 'value', dir: 1 },
    ]);
    const [movements, setMovements] = useState({ id: '', name: "", value: '' });

    useEffect(() => {
        GetList(pageNum);
    }, []);

    const GetList = (val) => {
        setSelectedPage(val);
        setIsLoader(true);
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        axios.post(`${apiUrl}${PORT}/admin/getmovement`, obj).then(function (response) {
            setIsLoader(false);
            debugger
            if (response.data.status === 1) {
                setNoOfRecords(response.data?.result?.noOfRecords || 0);
                setList(response.data?.result?.movement);
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
            }
        }).catch(function (error) {
            setIsLoader(false);
            swal({
                title: "Error!",
                text: error,
                icon: "error",
                button: true
            })
        });
    };

    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }

    const curPage = (pageNum) => {
        history.push(`basicMovements?p=${pageNum}`);
        setPageNum(pageNum);
        GetList(pageNum);
    }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            setIsLoader(true);
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                setIsLoader(false);
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }
    };

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };

    const isEdit = (obj) => {
        setMovements({ ...movements, id: obj._id, name: obj.name, value: obj.value });
        setShow(true);
    }
    const isDelete = async e => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Are you sure you Want to delete?",
            // text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: false,
            confirmButtonClass: "mx-2",
        }).then((result) => {
        if (result.isConfirmed) {
            setIsLoader(true);
            axios.post(`${apiUrl}${PORT}/admin/deletemovement`, { "id": e._id}, {}
            ).then(function (response) {
                setIsLoader(false);
                if (response.data.status === 1) {
                    GetList(pageNum ?? 1);
                    swal({
                        title: "Success!",
                        text: response.data.message,
                        icon: "success",
                        button: true
                    })
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }else if (result.dismiss === Swal.DismissReason.cancel) {
         
        }
        })

        // setIsLoader(true);
        // debugger
        // swal({
        //     title: "Delete confirmation!",
        //     text: "Are you sure you want to delete this?",
        //     icon: "warning",
        //     /* button: true, */
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes, delete it!'
        // }).then(function (result) {
        //     debugger;
        //     /* if (result.isConfirmed) {
        //     } */
        // })
        // /* if (window.confirm("Are you sure? You want to delete this?")) {
        //     await axios.post(`${apiUrl}${PORT}/admin/deletemovement`, { "id": e._id }, {}
        //     ).then(function (response) {
        //         setIsLoader(false);
        //         if (response.data.status === 1) {
        //             GetList(pageNum ?? 1);
        //             swal({
        //                 title: "Success!",
        //                 text: response.data.message,
        //                 icon: "success",
        //                 button: true
        //             })
        //         }
        //         else {
        //             swal({
        //                 title: "Error!",
        //                 text: response.data.message,
        //                 icon: "error",
        //                 button: true
        //             })
        //         }
        //     }).catch(function (error) {
        //         setIsLoader(false);
        //         swal({
        //             title: "Error!",
        //             text: error,
        //             icon: "error",
        //             button: true
        //         })
        //     });
        // } else {
        //     setIsLoader(false);
        // } */
    };
    const isShowModel = () => {
        setMovements({ ...movements, id: null, name: "", value: '' });
        setErrors({});
        setShow(true);
    }

    const saveMovements = (e) => {
        let isValid = true;
        var errormsg = {};

        if (movements.name === '') {
            errormsg.name = "Please select movement name!";
            isValid = false;
        }
        if (movements.value === '') {
            errormsg.value = "Please enter value!";
            isValid = false;
        }
        setErrors(errormsg);
        if (isValid) {
            setIsLoader(true);
            axios.post(`${apiUrl}${PORT}/admin/savemovement`, movements)
                .then(function (response) {
                    setErrors({});
                    setIsLoader(false);
                    setShow(false);
                    if (response.data.status === 1) {
                        // GetList(1);
                        GetList(pageNum);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: true
                        });
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            button: true
                        })
                    }
                }).catch(function (error) {
                    setIsLoader(false);
                    swal({
                        title: "Error!",
                        text: error,
                        icon: "error",
                        button: true
                    })
                });
        }
    }

    return (
        <>
            {isLoader &&
                <div className="loader">
                    <div className="popuploader loginloader"></div>
                </div>
            }
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Basic Movements</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">BasicMovements</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick} type="button"> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                            <div className="col-xl-2 col-md-2 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={(e) => { isShowModel(e) }}><i className="fa fa-plus pr-2"></i>Add</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="text-center w130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    return (<tr key={'index' + index}>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{ele?.name}</td>
                                                        <td>{ele?.value}</td>
                                                        <td className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                                <button className="btn btn-info mr-2" onClick={(e) => { isEdit(ele) }}><i className="ace-icon fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger mr-2" onClick={() => { isDelete(ele) }} ><i className="ace-icon fa fa-trash-o"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center no-record" colSpan="10">
                                                    No record found!
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={() => { setShow(false) }} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Basic Movements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3">
                        <div className="row">
                            <div className="col-md-6 col-sm-4 col-12">
                                <label>Select Movements</label>
                                <div className="input-group">
                                    <i className="fa fa-angle-down darrow-i"></i>
                                    <select className="input-box m-0" value={movements.name} onChange={(e) => {
                                        setMovements({ ...movements, name: e.target.value })
                                    }}>
                                        <option value="">Select any one</option>
                                        <option value="Weight">Weight</option>
                                        <option value="Rest Duration">Rest Duration</option>
                                        <option value="Sets">Sets</option>
                                        <option value="Reps">Reps</option>
                                    </select>
                                </div>
                                <div className='text-danger'>{errors.name}</div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Value</label>
                                <div className="input-group">
                                    <input className="input-box m-0" type="text" value={movements.value} onChange={(e) => { setMovements({ ...movements, value: e.target.value }) }} />
                                </div>
                                <div className='text-danger'>{errors.value}</div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn cancel_btn" onClick={(e) => { setErrors({}); setShow(false); }} >Cancel</Button>
                    <Button className="btn save_btn" variant="secondary" onClick={(e) => { saveMovements(); }}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BasicMovements;