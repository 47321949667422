import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl, PORT } from '../environment/environment';
import { Button, Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Pagination from './Pagination/Pagination';
import swal from 'sweetalert';
import Swal from 'sweetalert2';
function Subscribers() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));

    const [show, setShow] = useState(false);
    const [list, setList] = useState([]);
    const [plan, setPlan] = useState({ id: "", plantype: "", noofsession: "", amount: "", tax: "", paymentMethod: "", status: true });
    const [searchname, setSearchName] = useState('');
    const handleClose = () => setShow(false);
    //const handleShow = () => setShow(true);
    const [errors, setErrors] = useState({});
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Plan Type', value: 'plantype', dir: 1 },
        { name: 'No Of Session', value: 'noofsession', dir: 1 },
        { name: 'Amount', value: 'amount', dir: 1 },
        { name: 'Tax', value: 'tax', dir: 1 }
    ]);

    useEffect(() => {
        GetList(pageNum);
    }, []);
    async function GetList(val) {
        setSelectedPage(val);
        document.querySelector('.loader').classList.remove('d-none');
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        console.log("obj", obj);
        await axios.post(`${apiUrl}${PORT}/admin/getplan`, obj)
            .then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    setNoOfRecords(response.data?.result?.noOfRecords || 0);
                    setList(response.data.result?.planlist);
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
    };

    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };
    const curPage = (pageNum) => {
        history.push(`subscribers?p=${pageNum}`)
        setPageNum(pageNum);
        GetList(pageNum);
    }

    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            document.querySelector('.loader').classList.remove('d-none');
            await axios.post(`${apiUrl}${PORT}/admin/searchplans`, { "searchname": searchname }, {}
            ).then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error);
            });
        }
    };

    const isSave = (e) => {
        e.stopPropagation();
        let isValid = true;
        var errormsg = {};
        const reg = /^[0-9\b]+$/;

        if (plan.plantype === '') {
            errormsg.plantype = "Please select plantype!";
            isValid = false;
        }

        if (plan.amount === '') {
            errormsg.amount = "Please enter amount!";
            isValid = false;
        }else if(reg.test(plan.amount) === false){
            errormsg.amount = "Please enter valid amount!";
            isValid = false;
        }
        if (plan.tax === '') {
            errormsg.tax = "Please enter tax!";
            isValid = false;
        }else if(reg.test(plan.tax) === false){
            errormsg.tax = "Please enter valid tax!";
            isValid = false;
        }
        /* if (plan.paymentMethod === '') {
                            errormsg.paymentMethod = "Please select payment method!";
                        isValid = false;
        } */
        if (plan.noofsession === '') {
            errormsg.noofsession = "Please enter no of session!";
            // errormsg.paymentMethod = "Please enter no of session!";
            isValid = false;
        }
        setErrors(errormsg);
        if (isValid) {
            document.querySelector('.loader').classList.remove('d-none');
            axios.post(`${apiUrl}${PORT}/admin/saveplan`, plan)
                .then(function (response) {
                    document.querySelector('.loader').classList.add('d-none');
                    setShow(false);
                    if (response.data.status === 1) {
                        // GetList(1);
                        GetList(pageNum);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: true
                        })
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            button: true
                        })
                        //window.alert(response.data.message);
                    }
                }).catch(function (error) {
                    document.querySelector('.loader').classList.add('d-none');
                    window.alert(error);
                });
        }
    }

    const isEdit = (obj) => {
        setPlan({ ...plan, id: obj._id, plantype: obj.plantype, noofsession: obj.noofsession, amount: obj.amount, tax: obj.tax, paymentMethod: obj.paymentMethod, status: obj.statusid });
        setShow(true);
    }

    const isDelete = (val) => {
        let obj = {
            id: val._id
        }
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: "Are you sure you Want to delete?",
            // text: "You won't be able to revert this!",
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: false,
            confirmButtonClass: "mx-2",
        }).then((result) => {
            if (result.isConfirmed) {
                document.querySelector('.loader').classList.remove('d-none');
                axios.post(`${apiUrl}${PORT}/admin/deleteplan`, obj)
                    .then(function (response) {
                        document.querySelector('.loader').classList.add('d-none');
                        setShow(false);
                        if (response.data.status === 1) {
                            GetList(pageNum ?? 1);
                            swal({
                                title: "Success!",
                                text: response.data.message,
                                icon: "success",
                                button: true
                            })
                        }
                        else {
                            swal({
                                title: "Error!",
                                text: response.data.message,
                                icon: "error",
                                button: true
                            })
                            //window.alert(response.data.message);
                        }
                    }).catch(function (error) {
                        document.querySelector('.loader').classList.add('d-none');
                        window.alert(error);
                    });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
        // let obj = {
        //     id: val._id
        // }
        // var res = window.confirm("Are you sure you want to delete this record?")
        // if (res) {
        //     document.querySelector('.loader').classList.remove('d-none');
        //     axios.post(`${apiUrl}${PORT}/admin/deleteplan`, obj)
        //         .then(function (response) {
        //             document.querySelector('.loader').classList.add('d-none');
        //             setShow(false);
        //             if (response.data.status === 1) {
        //                 GetList(pageNum ?? 1);
        //             }
        //             else {
        //                 swal({
        //                     title: "Error!",
        //                     text: response.data.message,
        //                     icon: "error",
        //                     button: true
        //                 })
        //                 //window.alert(response.data.message);
        //             }
        //         }).catch(function (error) {
        //             document.querySelector('.loader').classList.add('d-none');
        //             window.alert(error);
        //         });
        // }
    }

    const isShowModel = () => {
        setPlan({ ...plan, id: "", plantype: "", noofsession: "", amount: "", tax: "", paymentMethod: "", status: true });
        setErrors({});
        setShow(true);
    }
    const onChangeHandle = (e, ele) => {
        e.stopPropagation();
        plan.id = ele._id;
        plan.plantype = ele.plantype;
        plan.noofsession = ele.noofsession;
        plan.amount = ele.amount;
        plan.tax = ele.tax;
        plan.paymentMethod = ele.paymentMethod;
        plan.status = e.currentTarget.checked ? 1 : 0;
        isSave(e);
    }
    const handleChange = (e) => {
        setPlan({ ...plan, [e.target.name]: e.target.value });
    }

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Subscribers / Payment setup</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Subscribers</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick}> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={(e) => { isShowModel() }}><i className="fa fa-plus pr-2"></i>Add</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    return (<tr>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{ele.plantype}</td>
                                                        <td>{ele.noofsession}</td>
                                                        <td>{ele.amount} $</td>
                                                        <td>{ele.tax}</td>
                                                        {/* <td>Cash</td> */}
                                                        <td>
                                                            <div className="onoffswitch">
                                                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={`myonoffswitch ${index}`} checked={ele.statusid === 1 ? true : false} onChange={(e) => { onChangeHandle(e, ele) }} />
                                                                <label className="onoffswitch-label" htmlFor={`myonoffswitch ${index}`}>
                                                                    <span className="onoffswitch-inner active-deactive"></span>
                                                                    <span className="onoffswitch-switch"></span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="d-flex justify-content-center">
                                                                <button className="btn btn-info mr-2" onClick={(e) => { isEdit(ele) }}><i className="ace-icon fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger mr-2" onClick={(e) => { isDelete(ele) }}><i className="ace-icon fa fa-trash-o"></i></button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center" colSpan="9">
                                                    No Record Found
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Subscribers / Payment Setup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Plan Type</label>
                                {/* <div className="input-group">
                                    <input className="input-box" type="text"
                                        value={plan.name} onChange={(e) => {
                                            setPlan({ ...plan, name: e.target.value })
                                        }} />
                                    <div className="text-danger">{errors.goal}</div>
                                </div> */}
                                <div className="input-group">
                                    <i className="fa fa-angle-down darrow-i"></i>
                                    <select className="input-box m-0"
                                        value={plan.plantype} 
                                        onChange={(event) => handleChange(event)}
                                        name="plantype"
                                        /* onChange={(e) => {
                                            setPlan({ ...plan, plantype: e.target.value })
                                        }} */ >

                                        <option value="">Choose plan</option>
                                        <option value="Standard">Standard</option>
                                        <option value="Elite">Elite</option>
                                    </select>
                                    <div className="text-danger">{errors.plantype}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>No Of Session</label>
                                <div className="input-group">
                                    <input className="input-box m-0" type="text"
                                        value={plan.noofsession} 
                                        onChange={(event) => handleChange(event)}
                                        name="noofsession"
                                        /* onChange={(e) => {
                                            setPlan({ ...plan, noofsession: e.target.value })
                                        }} */ />
                                    <div className="text-danger">{errors.noofsession}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Amount $</label>
                                <div className="input-group">
                                    <input className="input-box m-0" type="number"
                                        value={plan.amount} 
                                        onChange={(event) => handleChange(event)}
                                        name="amount"
                                        /* onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setPlan({ ...plan, amount: parseInt(e.target.value) })
                                            }
                                        }} */ />
                                    <div className="text-danger">{errors.amount}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Tax</label>
                                <div className="input-group">
                                    <input className="input-box m-0" type="number"
                                        value={plan.tax}
                                        name='tax'
                                        onChange={(event) => handleChange(event)}
                                    /* onChange={(e) => {
                                        // const re = /^[0-9\b]+\.[0-9]+$/;
                                        const re = /^[0-9\b]+$/;
                                        console.log("e.target.value === '' || re.test(e.target.value)", e.target.value === '' || re.test(e.target.value));
                                           if (e.target.value === '' || re.test(e.target.value)) {
                                             setPlan({ ...plan, tax: parseInt(e.target.value)});
                                               } 
                                               }
                                               
                                    } */
                                    />
                                    <div className="text-danger">{errors.tax}</div>
                                </div>

                            </div>
                            {/* <div className="col-lg-6 col-md-6 col-12">
                                <label>Payment Method</label>
                                <div className="input-group">
                                    <i className="fa fa-angle-down darrow-i"></i>
                                    <select className="input-box"
                                        value={plan.paymentMethod} onChange={(e) => {
                                            setPlan({ ...plan, paymentMethod: e.target.value })
                                        }}>
                                        <div className="text-danger">{errors.goal}</div>
                                        <option value=""></option>
                                        <option value="01">Cash</option>
                                        <option value="02">Check</option>
                                        <option value="03">Debit card</option>
                                        <option value="04">Credit card</option>
                                        <option value="05">Mobile payment</option>
                                    </select>
                                </div>
                            </div> */}

                            <div className="col-md-12 col-sm-6 col-12">
                                <label>Status</label>
                                <div className="onoffswitch m-0">
                                    <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitchedit"
                                        checked={plan.status === 1 ? true : false} value={plan.status} onChange={(e) => { setPlan({ ...plan, status: e.currentTarget.checked ? 1 : 0 }) }} />
                                    <label className="onoffswitch-label" htmlFor="myonoffswitchedit">
                                        <span className="onoffswitch-inner active-deactive"></span>
                                        <span className="onoffswitch-switch"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn cancel_btn" onClick={handleClose} >Cancel</Button>
                    <Button className="btn save_btn" variant="secondary" onClick={(e) => { isSave(e) }}>{plan.id ? 'Update' : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default Subscribers;