import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl, PORT } from '../environment/environment';
import { Rating } from 'react-simple-star-rating';
import { Modal } from "react-bootstrap";
import { Link, useHistory, useLocation } from 'react-router-dom';
import Pagination from './Pagination/Pagination';
import swal from 'sweetalert';
function ClientRatings() {
    let history = useHistory();
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    let query = useQuery();
    let pageNumber = parseInt(query.get("p"));
    const [show, setShow] = useState(false);
    const [list, setList] = useState([]);
    const [ratingView, setRatingView] = useState({ name: '', rating: null, review: "" });
    const [searchname, setSearchName] = useState('');
    const handleClose = () => setShow(false);
    const [pageNum, setPageNum] = useState(pageNumber || 1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Client Name', value: 'firstname', dir: 1 },
        { name: 'Review Date', value: 'date', dir: 1 },
    ]);

    useEffect(() => {
        GetRatingList(pageNum);
    }, []);
    async function GetRatingList(val) {
        setSelectedPage(val);
        document.querySelector('.loader').classList.remove('d-none');
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };

        await axios.post(`${apiUrl}${PORT}/admin/ratinglist`, obj)
            .then(function (response) {
                document.querySelector('.loader').classList.add('d-none');
                if (response.data.status === 1) {
                    setNoOfRecords(response.data?.result[0]?.totalCount[0]?.count || 0);
                    setList(response.data?.result[0]?.paginatedResults);
                }
                else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                    //window.alert(response.data.message);
                }
            }).catch(function (error) {
                document.querySelector('.loader').classList.add('d-none');
                window.alert(error);
            });
    };
    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetRatingList(selectedPage);
    }

    // const deleteRating = (val) => {
    //     let obj = {
    //         id: val._id
    //     }
    //     document.querySelector('.loader').classList.remove('d-none');
    //     axios.post(`${apiUrl}${PORT}/admin/deleterating`, obj)
    //         .then(function (response) {
    //             document.querySelector('.loader').classList.add('d-none');
    //             setShow(false);
    //             if (response.data.status === 1) {
    //                 GetRatingList(1);
    //             }
    //             else
    //                 window.alert(response.data.message);
    //         }).catch(function (error) {
    //             document.querySelector('.loader').classList.add('d-none');
    //             window.alert(error);
    //         });
    // }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const clearClick = async e => {
        setSearchName('');
        GetRatingList(1);
    };

    const searchClick = async e => {
        /* if (!searchname)
            GetRatingList(1);
        else {
            document.querySelector('.loading').classList.remove('d-none');
            await axios.post(`${apiUrl}${PORT}/trainer/trainer/searchtrainer`, { "searchname": searchname }, {}
            ).then(function (response) {
                document.querySelector('.loading').classList.add('d-none');
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    window.alert(response.data.message);
                }
            }).catch(function (error) {
                window.alert(error);
            });
        } */
    };

    const isViewRating = (obj) => {
        setRatingView({ ...ratingView, name: obj.client_data.firstname, rating: obj.sessionrating.rate, review: obj.sessionrating.review });
        setShow(true);
    }

    const curPage = (pageNum) => {
        history.push(`clientratings?p=${pageNum}`)
        setPageNum(pageNum);
        GetRatingList(pageNum);
    }

    return (
        <>
            <div className="loader d-none">
                <div className="popuploader loginloader"></div>
            </div>
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Client Ratings</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Client Ratings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick}> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table review mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th>Rating</th>
                                                <th>Rating Option</th>
                                                <th className="text-center w130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.length > 0 ?
                                                list.map((ele, index) => {
                                                    return (<tr key={'index' + index}>
                                                        <td>{(index + 1) + ((pageNum - 1) * limitValue)}</td>
                                                        <td>{ele.client_data.firstname} {ele.client_data.lastname}</td>
                                                        <td>{new Date(ele.date).toDateString()}</td>
                                                        <td>
                                                            <Rating initialValue={ele.sessionrating.rate / 20} size="17" readonly="true" allowHover="false" allowHalfIcon="true" />
                                                        </td>
                                                        <td>{ele.sessionrating.ratingOpt.map((rs, i) => {
                                                            return (<p key={'index' + i}>{rs}</p>);
                                                        })}</td>
                                                        <td className="text-center">
                                                            <a className="btn btn-eye mr-2" href={() => false} onClick={(e) => { isViewRating(ele) }} ><i className="ace-icon fa fa-eye"></i></a>
                                                            {/* <button className="btn btn-danger" onClick={(e) => { deleteRating(ele) }}><i className="ace-icon fa fa-trash-o"></i></button> */}
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center" colSpan="9">
                                                    No Record Found
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {list.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg" scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Client Ratings</Modal.Title>
                </Modal.Header>
                <Modal.Body className="rating-modal">
                    <div className="col-md-12 pb-3 booking-history">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-12">
                                <div className="row">
                                    <div className="d-flex">
                                        <div className="profile-img">
                                            <img src="/img/user-1.jpg" alt="User" />
                                        </div>
                                        <div className="client-review">
                                            {console.log("ratingView", ratingView)}
                                            <span className="rating-name">{ratingView.name}</span>
                                            {/* <div className="rating_star">
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                                <i className="fa fa-star"></i>
                                            </div> */}
                                            <Rating initialValue={ratingView.rating / 20} size="17" readonly="true" allowHover="false" allowHalfIcon="true" />
                                            <p>{ratingView.review ? ratingView.review : "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default ClientRatings;