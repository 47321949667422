import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiUrl, PORT } from '../environment/environment';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
function Login() {
    const history = useHistory();
    const usertype = localStorage.getItem('usertype');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = token;
            axios.get(`${apiUrl}${PORT}/account/verifytoken`, {}, {
            }).then(function (response) {
                if (response.data.status === 1) {
                    history.push("/usermanagement");
                    window.location.reload();
                    // window.location.href = "/usermanagement";
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    });

    localStorage.setItem('usertype', 'admin');

    const [user, setUser] = useState({
        email: "", password: ""
    });

    const handleInputs = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const PostLogin = async (e) => {
        e.preventDefault();

        if (user.email === "") {
            //window.alert("Please enter email.");
            swal({
                title: "Error!",
                text: "Please enter email.",
                icon: "error",
                button: true
            })
            return false;
        }
        else if (user.password === "") {
            swal({
                title: "Error!",
                text: "Please enter Password.",
                icon: "error",
                button: true
            })
            //window.alert("Please enter Password.");
            return false;
        }

        document.querySelector('.loader').classList.remove('d-none');
        await axios.post(`${apiUrl}${PORT}/${usertype}/account/login`, { "email": user.email, "password": user.password }, {
        }).then(function (response) {
            document.querySelector('.loader').classList.add('d-none');
            if (response.data.status === 1) {
                axios.defaults.headers.common['Authorization'] = response.data.result.token;
                localStorage.setItem('user', response.data.result.User);
                localStorage.setItem('usertype', usertype);
                localStorage.setItem('token', response.data.result.token);

                //window.alert(response.data.message);
                // window.location.href = "/usermanagement";
                history.push("/usermanagement");
                window.location.reload();
            }
            else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
                //window.alert(response.data.message);
            }
        }).catch(function (error) {
            document.querySelector('.loader').classList.add('d-none');
            console.log(error);
        });
    }

    return (
        <>
            <div className="bg-img">
                <div className="container">
                    <div className="col-md-12 col-sm-12 col-12">
                        <div className="row log-m">
                            <div className="col-xl-3 offset-xl-2 col-lg-4 offset-lg-1 col-12 login-bg">
                                <div className="login-bg">
                                    <div className="log_logo">
                                        <img src="/img/KNKTLogo.png" alt="Logo" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-12 p-0">
                                <div className="login-box">
                                    <div className="loader d-none">
                                        <div className="popuploader loginloader"></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="position-relative">
                                                <input onChange={(e) => handleInputs(e)} name="email" className="input-box" type="text" placeholder="Email" />
                                                <i className="fa fa-user log_i"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12">
                                            <div className="position-relative">
                                                <input onChange={(e) => handleInputs(e)} name="password" className="input-box" type="password" placeholder="Password" />
                                                <i className="fa fa-lock log_i"></i>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12 mb-2 dispinline pl-0 mt-2">
                                            <div className="checkbox checkbox-primary float-sm-left">
                                                <input id="RememberMe" type="checkbox" />
                                                <label htmlFor="RememberMe">
                                                    Remember Me
                                                </label>
                                            </div>
                                            <div className="frgot_pass float-sm-right">
                                                <Link to="forgotpassword">Forgot password?</Link>
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-sm-12 col-12 text-right mt-2">
                                            <button onClick={(e) => PostLogin(e)} type="submit" className="log_btn">Login</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default Login;
