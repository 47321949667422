import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { apiUrl, PORT } from '../../environment/environment';
import Pagination from '../Pagination/Pagination';

const MyEnum = {
    SESDLY: 'SESDLY',
    STNCHR: 'STNCHR',
    ELTCHR: 'ELTCHR',
}


function Settings() {

    const [show, setShow] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [searchname, setSearchName] = useState('');
    const [pageNum, setPageNum] = useState(1);
    const [noOfRecords, setNoOfRecords] = useState(0);
    const [List, setList] = useState([]);
    const handleClose = () => setShow(false);
    const [setting, setSetting] = useState({ id: null, key: "", code: "", val: null });
    const [errors, setErrors] = useState({});
    const [isSortedCol, setIsSortedCol] = useState();
    const [selectedPage, setSelectedPage] = useState(1);
    const limitValue = 10;
    const [columnArr, setColumnArr] = useState([
        { name: 'Key', value: 'key', dir: 1 },
        { name: 'Code', value: 'code', dir: 1 },
        { name: 'Value (Minute)', value: 'val', dir: 1 },
    ]);

    useEffect(() => {
        GetList(1);
    }, []);

    const GetList = (val) => {
        setSelectedPage(val);
        setIsLoader(true);
        var obj = {
            limitValue: limitValue,
            pageNumber: (val || pageNum),
            sortedCol: isSortedCol,
            sortedOrder: columnArr.filter(x => x.value === isSortedCol)[0]?.dir
        };
        axios.post(`${apiUrl}${PORT}/admin/settinglist`, obj).then(function (response) {
            setIsLoader(false);
            if (response.data.status === 1) {
                setNoOfRecords(response.data?.result?.noOfRecords || 0);
                setList(response.data?.result?.settinglist);
            } else {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    icon: "error",
                    button: true
                })
            }
        }).catch(function (error) {
            setIsLoader(false);
            window.alert(error);
        });
    };

    const setCodeValue = (value) => {
        if (MyEnum.SESDLY)
            return value + ' (Minute)';
        else if (MyEnum.STNCHR || MyEnum.ELTCHR)
            return value + ' $';
    }

    const curPage = (pageNum) => {
        setPageNum(pageNum);
        GetList(pageNum);
    }

    const handlsearchInputs = (e) => {
        setSearchName(e.target.value);
    }

    const searchClick = async e => {
        if (!searchname)
            GetList(1);
        else {
            setIsLoader(true);
            await axios.post(`${apiUrl}${PORT}/admin/searchsettings`, { "searchname": searchname }, {}
            ).then(function (response) {
                setIsLoader(false);
                if (response.data.status === 1) {
                    setList(response.data.result);
                } else {
                    swal({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                        button: true
                    })
                }
            }).catch(function (error) {
                setIsLoader(false);
                window.alert(error);
            });
        }
    };

    const clearClick = async e => {
        setSearchName('');
        GetList(1);
    };

    const isEdit = (obj) => {
        setSetting({ ...setting, id: obj._id, key: obj.key, code: obj.code, val: obj.val });
        setShow(true);
    }

    const isSave = (e) => {
        e.stopPropagation();
        let isValid = true;
        var errormsg = {};

        if (setting.val === '') {
            errormsg.value = "Please enter value!";
            isValid = false;
        }
        setErrors(errormsg);
        if (isValid) {
            setIsLoader(true);
            axios.post(`${apiUrl}${PORT}/admin/savesetting`, setting)
                .then(function (response) {
                    setIsLoader(false);
                    setShow(false);
                    setErrors({});
                    if (response.data.status === 1) {
                        GetList(1);
                        swal({
                            title: "Success!",
                            text: response.data.message,
                            icon: "success",
                            button: true
                        });
                    }
                    else {
                        swal({
                            title: "Error!",
                            text: response.data.message,
                            icon: "error",
                            button: true
                        })
                    }
                }).catch(function (error) {
                    setIsLoader(false);
                    window.alert(error);
                });
        }
    }
    const isSortedColumn = (col, order) => {
        columnArr.forEach(ele => {
            if (ele.value === col) {
                ele.dir = ele.dir === 1 ? -1 : 1;
            }
        });
        setColumnArr(columnArr);
        setIsSortedCol(col);
        GetList(selectedPage);
    }
    return (
        <>
            {isLoader &&
                <div className="loader">
                    <div className="popuploader loginloader"></div>
                </div>
            }
            <div className="container-fluid">
                <div className="title-box">
                    <div className="row">
                        <div className="col-md-6 col-sm-6 col-12">
                            <h4 className="mb-0">Settings</h4>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12 text-right">
                            <div className="page-title float-right">
                                <ul className="breadcrumb m-0">
                                    <li className="breadcrumb-item"><Link to="/usermanagement">Home</Link></li>
                                    <li className="breadcrumb-item active">Settings</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 col-sm-12 col-12 p-0">
                    <div className="box-card">
                        <div className="row">
                            <div className="col-xl-4 col-md-6 col-sm-12 col-12">
                                <div className="input-group">
                                    <input className="input-box" type="text" placeholder="Search..." onChange={(e) => handlsearchInputs(e)} name="searchinput" value={searchname} />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn mt-0" onClick={searchClick} type="button"> <i className="fa fa-search pr-2"></i>Search</button>
                            </div>
                            <div className="col-xl-2 col-md-3 col-sm-6 col-12">
                                <button className="search_btn clear_btn mt-0" onClick={clearClick}><i className="fa fa-eraser pr-2"></i>Clear</button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 col-12 record_table mt-2">
                                <div className="table-responsive">
                                    <table className="table table-bordered table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th>Sr. No</th>
                                                {
                                                    columnArr.map((col, i) => {
                                                        return <th style={{ cursor: 'pointer' }} onClick={() => isSortedColumn(col.value, col.dir)}><div className="d-flex justify-content-between">{col.name}
                                                            <span className="align-self-center">
                                                                <i className="ml-2 fa fa-sort sort-gray"></i>
                                                                <i className={`${col.dir === 1 ? 'sort-i fa fa-sort-asc' : 'sort-i fa fa-sort-desc'}`}></i>
                                                            </span>
                                                        </div>
                                                        </th>
                                                    })
                                                }
                                                <th className="text-center w130">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {List.length > 0 ?
                                                List.map((ele, i) => {
                                                    return (<tr key={'index' + i}>
                                                        <td>{i + 1}</td>
                                                        <td>{ele.key}</td>
                                                        <td>{ele.code}</td>
                                                        <td>{setCodeValue(ele.val)}</td>
                                                        <td className="text-center">
                                                            <button className="btn btn-info mx-auto" onClick={(e) => { isEdit(ele) }}><i className="ace-icon fa fa-pencil"></i></button>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                                :
                                                <td className="text-center no-record" colSpan="10">
                                                    No record found!
                                                </td>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {List.length > 0 &&
                                    <div className="col-md-12 col-sm-12 col-12 pagi_bg">
                                        <Pagination className="pagination-bar" currentPage={pageNum} totalCount={noOfRecords}
                                            pageSize={limitValue} onPageChange={page => curPage(page)} />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} size="lg" scrollable={true} aria-labelledby="contained-modal-title-vcenter" centered>
                <div id="loadermodal" className="loader d-none">
                    <div className="popuploader loginloader"></div>
                </div>
                <Modal.Header closeButton>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12 pb-3">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Key</label>
                                <div className="input-group">
                                    <input className="input-box" type="text" value={setting.key} disabled={setting.id ? true : false} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Code</label>
                                <div className="input-group">
                                    <input className="input-box" type="text" value={setting.code} disabled={setting.id ? true : false} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <label>Value (Minute)</label>
                                <div className="input-group">
                                    <input className="input-box" type="text" value={setting.val} onChange={(e) => {
                                        setSetting({ ...setting, val: e.target.value });
                                    }} />
                                    <div className="text-danger">{errors.value}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn cancel_btn" onClick={(e) => { setErrors({}); setShow(false); }} >Cancel</Button>
                    <Button className="btn save_btn" variant="secondary" onClick={(e) => { isSave(e) }}>{setting.id ? 'Update' : 'Save'}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}

export default Settings;